export enum Site {
     start,
     table,
     target,
     tableWithGroup
}

export class Translation{
      static HOW_TO_USE_SCREEN_REG = "HOW_TO_USE_SCREEN_REG"
     static FOLLOW_MOBILE_INSTRUCTION= "FOLLOW_MOBILE_INSTRUCTION"
     static NO_CONNECTION = "NO_CONNECTION"
     static RESET_REGISTRATION = "RESET_REGISTRATION"
     static WELCOME_AT_SHOOTINGRANGE = "WELCOME_AT_SHOOTINGRANGE"
     static THIS_IS_SCREEN = "THIS_IS_SCREEN"
     static RANK = "RANK"
     static SHOOTER = "SHOOTER"
     static RESULT = "RESULT"
     static BESTSHOT_SHORT = "BESTSHOT_SHORT"
     static YEAR_OF_BIRTH = "YEAR_OF_BIRTH"
     static AGE_CATEGORY = "AGE_CATEGORY"
     static AWARD_SHORT = "AWARD_SHORT"
     static WEAPON_TYPE = "WEAPON_TYPE"
     static ASSOCIATION_NAME = "ASSOCIATION_NAME"
     static RANK_LIST = "RANK_LIST"
     static RESULTS =    "RESULTS"
     static CURRENT_TOTAL_SHORT = "CURRENT_TOTAL_SHORT"
}

export class Target_Type {
     static G300B4_TARGET = "G300B4_TARGET"
     static G300A10_TARGET = "G300A10_TARGET"
}