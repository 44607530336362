import {DataSource, GroupScorelistEntry, LaneData, ScoreListEntry, TableConfiguration} from "../models/Models";
import {CSSProperties} from "react";


function TableView(props: TableViewProps){

    function getTableRows(){
        var listData:any[] = [];

        switch (props.tableConfiguration?.dataSource){
            case DataSource.Value0:
                listData = props.scoreList ?? [];
                break;
            case DataSource.Value1:
                listData = props.laneData ?? [];
                break;
            case DataSource.Value2:
                listData = props.scoreList ?? [];
                break;
        }

            return listData.map(data => {
                return <tr className={"d-flex"}>
                    {
                        props.tableConfiguration?.tableColumnConfigurations?.map(c => {
                            var subtableDataFields = c.dataField?.split(".") ??[];

                            return c.isSubTable ?
                                <td style={{
                                    fontSize: c.fontSize,
                                    fontWeight: c.bold ? "bold" : "normal",
                                    width: `${c.width}%`,
                                    textAlign: c.textAlign == "start" ? "start" : c.textAlign == "end" ? "end" : "center",
                                    paddingLeft: c.marginLeft ?? 0,
                                    paddingRight: c.marginRight ?? 0
                                }}>
                                    {
                                        data[subtableDataFields[0]].length > (c.subTableIndex??0) ?
                                            data[subtableDataFields[0]][c.subTableIndex??0][subtableDataFields[1]]
                                            : ""
                                    }
                                </td>
                                :
                                <td style={{
                                    fontSize: c.fontSize,
                                    fontWeight: c.bold ? "bold" : "normal",
                                    width: `${c.width}%`,
                                    textAlign: c.textAlign == "start" ? "start" : c.textAlign == "end" ? "end" : "center",
                                    paddingLeft: c.marginLeft ?? 0,
                                    paddingRight: c.marginRight ?? 0
                                }}>
                                    {
                                        data[c.dataField!]
                                    }
                                </td>
                        })
                    }
                </tr>
            });

        return <tr></tr>
    }

    function getTable() {
        return <table className={"table table-striped"}>
            <thead>
            <tr className={"d-flex"}>
                {
                    props.tableConfiguration?.tableColumnConfigurations?.map(c => {
                        return <th style={{
                            fontSize: c.fontSize,
                            width: `${c.width}%`,
                            textAlign: c.textAlign == "start" ? "start" : c.textAlign == "end" ? "end" : "center",
                            paddingLeft: c.marginLeft ?? 0,
                            paddingRight: c.marginRight ?? 0
                        }}>
                            {c.headerName}
                        </th>
                    })
                }
            </tr>
            </thead>
            <tbody>
            {
                getTableRows()
            }
            </tbody>
        </table>
    }

    function getContainer() {
        if (props.hasGroupSide) {
            var fontSize = props.tableConfiguration?.tableColumnConfigurations ? props.tableConfiguration?.tableColumnConfigurations[0].fontSize : 25;

            var stRank: CSSProperties = {fontSize: fontSize,
                width: `10%`,
                textAlign: "end",
                fontWeight: "bold",
                paddingLeft: 0,
                paddingRight: 5}
            var stPoints: CSSProperties = {fontSize: fontSize,
                width: `20%`,
                textAlign: "end",
                fontWeight: "bold",
                paddingLeft: 5,
                paddingRight: 10}
            var stGroup: CSSProperties = {fontSize: fontSize,
                //width: `10%`,
                textAlign: "start",
                fontWeight: "bold",
                paddingLeft: 15,
                paddingRight: 5};

            return <div className={"container-fluid"}>
                <div className={"row"}>
                    <div className={"col-9"}>
                        {getTable()}
                    </div>
                    <div className={"col-3"}>
                        <table className={"table table-striped"}>
                            <thead>
                            <tr>
                                <th style={stRank}>R.</th>
                                <th style={stPoints}>Pkte</th>
                                <th style={stGroup}>Gruppe</th>
                            </tr>
                            </thead>
                            <tbody>
                            {props.groupScoreList?.map(s => {
                                return <tr>
                                    <td style={stRank}>{s.rank}</td>
                                    <td style={stPoints}>{s.result?.toFixed(0)}</td>
                                    <td style={stGroup}>{s.groupName}</td>
                                </tr>
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        }
        return <div className={"container-fluid"}>
            {getTable()}
        </div>
    }

    return <div>
        <h1>{props.header}</h1>
        {getContainer()}
    </div>
}

interface TableViewProps {
    header: string;
    tableConfiguration: TableConfiguration | undefined;
    scoreList: ScoreListEntry[] | undefined | null;
    laneData: LaneData[] | undefined | null;
    hasGroupSide: boolean | undefined | null;
    groupScoreList: GroupScorelistEntry[] | undefined | null;
}

export default TableView;