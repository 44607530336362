import {Component} from "react"

import QRCode from "qrcode.react";
import WinfireLogo from "../images/LogoEinzelnNoCompanyCenter.svg";
import {LocalScreenSettingDto, ScreenRegistrationDto, ScreenSettingDto} from "../Dto/FullScreenDataSetDto";
import {SignalRHelper} from "../context/SignalRHelper";
import {Site, Translation} from "../helper/WinfireLiveConstHelper";
import {withTranslation} from "react-i18next";
import TypeConverter from "../helper/TypeConverter";


class Registerscreen extends Component<RegisterScreenProperties, RegisterScreenState>{

    constructor(props : RegisterScreenProperties) {
        super(props);
        this.state = {
            screenRegister: {guid: props.screenRegister?.guid || "", screenId: props.screenRegister?.screenId || "", shootingRangeId: props.screenRegister?.shootingRangeId || "", screenName: props.screenRegister?.screenName || "", shootingRangeName :props.screenRegister?.shootingRangeName || ""}
        }

        this.deleteScreenValues = this.deleteScreenValues.bind(this)

    }

    baseurl = process.env.REACT_APP_SCREEN_REGISTRATION_ENDPOINT

    componentDidUpdate(prevProps: Readonly<RegisterScreenProperties>, prevState: Readonly<RegisterScreenState>, snapshot?: any) {
        this.getSignalR();
    }

    deleteScreenValues(event: React.MouseEvent<HTMLButtonElement>){

            localStorage.clear();
            event.preventDefault()
            var screenRegister = this.state.screenRegister;

            screenRegister = {
                guid:"",
                screenId: "",
                shootingRangeId: "",
                shootingRangeName: "",
                screenName: "123"
            }

            this.setState({
                screenRegister: screenRegister
            })

    }


    getQRCode(){
        if(this.state.screenRegister.screenId !== "" && this.state.screenRegister.screenId != null){
            return <div className={"row"}>
                <div className={"gy-md-5 col-12"}>
                    <h1 className={"flextext"}>{this.props.t(Translation.WELCOME_AT_SHOOTINGRANGE)} {this.state.screenRegister.shootingRangeName}</h1>
                    <h2 className={"flextext"}>{this.props.t(Translation.THIS_IS_SCREEN)} {this.state.screenRegister.screenName}</h2>
                    <button className={"btn-dark flextextsmall"} onClick={this.deleteScreenValues}>{this.props.t(Translation.RESET_REGISTRATION)}</button>
                </div>
            </div>
        } else if (this.state.screenRegister.guid !== "" && this.state.screenRegister.guid != null) {
            return <div>
                <div className={"row"}>
                    <div className={"gy-md-5 col-12"}>
                        <h1 className={"flextext"}>{this.props.t(Translation.HOW_TO_USE_SCREEN_REG)}</h1>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={" gy-md-5 col-12"}><QRCode
                        id="registerQrCode"
                        size={window.innerHeight/3}
                        value={this.baseurl + this.state.screenRegister?.guid}
                        bgColor="white"
                        fgColor="black"
                        level="H"
                        imageSettings={
                            {
                                src: WinfireLogo,
                                x: undefined,
                                y: undefined,
                                height: window.innerHeight/15,
                                width: window.innerHeight/15,
                                excavate: true,
                            }
                        }/><br/> {this.state.screenRegister?.guid} </div>
                </div>
                <div className={"row"}>
                    <div className={"gy-md-5 col-12"}>
                        <h2 className={"flextext"}>{this.props.t(Translation.FOLLOW_MOBILE_INSTRUCTION)}</h2>
                    </div>
                </div>
            </div>
        } else
            return <div className={"row"}>
                <div className={"gy-md-5 col-12"}>
                    <h1>{this.props.t(Translation.NO_CONNECTION)}</h1>
                </div>
            </div>
    }

    saveScreenInfos2LocalStorage(screemRegistrationDto : ScreenRegistrationDto) :ScreenSettingDto{
        var screenSettingDto: LocalScreenSettingDto = {
            screenID: screemRegistrationDto.screenId,
            shootingRangeId: screemRegistrationDto.shootingRangeId,
            screenType: Site.start,
            showResulatTable: false,
            date: "",
            eventName: "Startup Screen",
            eventProviderName: "",
            xCountTargets: 0,
            yCountTargets: 0,
            shootingRangeName: screemRegistrationDto.shootingRangeName,
            screenName:screemRegistrationDto.screenName,
            rankListName:""
        }
        // @ts-ignore
        localStorage.setItem(process.env.REACT_APP_BROWSER_LOCALSTORE_NAME, JSON.stringify(screenSettingDto));
        return TypeConverter.convertLocalToStdScreenSettingDto(screenSettingDto);
    }


    componentDidMount() {
        this.getSignalR();
    }


    async getSignalR() {
        if (this.state.screenRegister?.screenId === "") {
            await SignalRHelper.registerScreen((screenRegistrationDto: ScreenRegistrationDto) => {
                this.setState({
                    screenRegister: screenRegistrationDto
                })
                if(screenRegistrationDto.shootingRangeId!= null && screenRegistrationDto.shootingRangeId.length>1){
                    this.props.updateState(this.saveScreenInfos2LocalStorage(screenRegistrationDto)); //TODO
                }
                console.log("GUID : " + screenRegistrationDto.guid + " ScreenID : " + screenRegistrationDto.screenId);
            })
        }
    }


    render() {
        return <div className={"container-fluid"}>
            {this.getQRCode()}
        </div>
    }
}

interface RegisterScreenProperties {
    screenRegister: ScreenRegistrationDto;
    updateState: Function,
    t: Function
}

interface RegisterScreenState {
    screenRegister: ScreenRegistrationDto;
}

export default withTranslation()(Registerscreen);