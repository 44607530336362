import React, {Component} from "react";
import {XTarget} from "./XTarget";
import {TwoTarget} from "./twoTargets";
import {
    FullScreenDataSetDto,
    LocalScreenSettingDto,
    ScreenRegistrationDto,
    ScreenSettingDto
} from "../Dto/FullScreenDataSetDto";
import ListView from "./listView";
import {SignalRHelper} from "../context/SignalRHelper";
import Registerscreen from "./registerscreen";
import {Site} from "../helper/WinfireLiveConstHelper";
import Ranklist from "./ranklist";
import {TopLive} from "../component/topLive";
import {withTranslation} from "react-i18next";
import TypeConverter from "../helper/TypeConverter";
import {ScreenDataSet} from "../models/Models";
import TableView from "../component/TableView";
import TargetView from "../component/TargetView";



class MainStage extends Component<MainStageProperties, MainStageState> {

    isSignalconnected = false;


    constructor(props: MainStageProperties) {
        super(props);

        let screenSettings = this.getLocalScreenSettings();



        this.state = {
            fullScreenDate: {
                shots: [],
                shootingProgramm: {
                    shootingProgrammID: 258,
                    name: "TestShootProgramm",
                    numberOfShots: 5,
                    sizeExerciseSequence: 2
                },
                screenSettingDto: TypeConverter.convertLocalToStdScreenSettingDto(screenSettings),
                shooters: [],
                group: [],
                exerciseSequences: undefined
            },
            screenState: Site.start,
            dataSet: {
                screenConfiguration: {
                    screenDisplaySettings: {
                        screenType: 99
                    }
                }
            }
        }

        this.updateParentState = this.updateParentState.bind(this);

    }

    getLocalScreenSettings() :LocalScreenSettingDto {

        let data = "";
        if(process.env.REACT_APP_BROWSER_LOCALSTORE_NAME !== undefined){
            data = process.env.REACT_APP_BROWSER_LOCALSTORE_NAME;
        }

        let screenSettingsTmp  = localStorage.getItem(data);
        var screeSettings: LocalScreenSettingDto = {
            screenType: 0,
            screenID: "",
            shootingRangeId: "",
            eventName:"",
            date: "",
            eventProviderName: "",
            showResulatTable: false,
            xCountTargets: 0,
            yCountTargets: 0,
            shootingRangeName: "",
            screenName:"",
            rankListName:""
        };
        if(screenSettingsTmp != null){
            screeSettings = JSON.parse(screenSettingsTmp);
        }
        return screeSettings;
    }

    componentDidUpdate(prevProps: Readonly<MainStageProperties>, prevState: Readonly<MainStageState>, snapshot?: any) {
        if(!this.isSignalconnected)
            this.getSignalR();
    }

    componentDidMount() {
        this.getSignalR();
    }

    async getSignalR() {
        if(this.state.fullScreenDate.screenSettingDto?.screenID != null && this.state.fullScreenDate.screenSettingDto.screenID.length > 0 && this.state.fullScreenDate.screenSettingDto.screenID[0] !== ""){
            await SignalRHelper.joinScreen(this.state.fullScreenDate.screenSettingDto?.screenID[0], this.state.fullScreenDate.screenSettingDto?.shootingRangeId, (fullDate: ScreenDataSet) => {
                if(fullDate.screenConfiguration?.screenIDs?.includes(this.getLocalScreenSettings().screenID)){

                    if(fullDate.screenConfiguration?.screenPhysicalSettings?.xResolution !== undefined && fullDate.screenConfiguration?.screenPhysicalSettings?.yResolution !== undefined && fullDate.screenConfiguration?.screenPhysicalSettings.xResolution > 0){
                        let x_resolution = fullDate.screenConfiguration.screenPhysicalSettings.xResolution;
                        let y_resolution = fullDate.screenConfiguration.screenPhysicalSettings.yResolution;
                        //alert("Restart Screen in 5 sec");
                        setTimeout(function(){
                            fetch('http://localhost:5001/api/ConfigData/screenresolution', {
                                method: 'PUT',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                body: JSON.stringify({
                                    "ScreenResolution1": x_resolution + "x" + y_resolution,
                                    "ScreenResolution2": ""
                                }) // Convert the Javascript object into a string
                            })
                                .then(response => response.text())
                                .then(data => console.log(data))
                                .catch((error) => {
                                    console.error('Error:', error);
                                });
                            }, 5000
                        )
                    }
                    this.setState(
                        {
                            dataSet: fullDate,
                            screenState: this.evaluateScreen(fullDate.screenConfiguration?.screenDisplaySettings?.screenType)
                        }
                    )
                }
            })
            this.isSignalconnected = true;
        }
    }


    updateParentState(screenSettingDto:ScreenSettingDto){
        this.setState({
            fullScreenDate: {
                screenSettingDto: screenSettingDto,
                shots: [],
                shootingProgramm: {
                    shootingProgrammID: 0,
                    name: "",
                    numberOfShots: 5,
                    sizeExerciseSequence: 5
                },
                shooters: [],
                group: [],
                exerciseSequences: undefined
            }
        })
    }

    getSubElement(){
        var screenRegister: ScreenRegistrationDto = {
            screenId:"",
            shootingRangeId:"",
            screenName:"",
            shootingRangeName:"",
            guid:""
        }
        if(this.state.fullScreenDate.screenSettingDto!= null && this.state.fullScreenDate.screenSettingDto!==undefined){
            screenRegister.screenName = this.state.fullScreenDate.screenSettingDto.screenName;
            screenRegister.screenId = this.state.fullScreenDate.screenSettingDto.screenID[0];
            screenRegister.shootingRangeName = this.state.fullScreenDate.screenSettingDto.shootingRangeName;
            screenRegister.shootingRangeId = this.state.fullScreenDate.screenSettingDto.shootingRangeId;
        }

        switch (this.state.screenState) {
            case Site.start : {
                return <Registerscreen screenRegister={screenRegister} updateState={this.updateParentState}/>
            }

            case Site.table:
                return <TableView header={this.state.dataSet.screenConfiguration?.screenDisplaySettings?.header ?? ""} tableConfiguration={this.state.dataSet.screenConfiguration?.screenDisplaySettings?.tableConfiguration} scoreList={this.state.dataSet.scoreListEntries} laneData={this.state.dataSet.laneDatas} hasGroupSide={false} groupScoreList={this.state.dataSet.groupScorelistEntries} />

            case Site.tableWithGroup:
                return <TableView header={this.state.dataSet.screenConfiguration?.screenDisplaySettings?.header ?? ""} tableConfiguration={this.state.dataSet.screenConfiguration?.screenDisplaySettings?.tableConfiguration} scoreList={this.state.dataSet.scoreListEntries} laneData={this.state.dataSet.laneDatas} hasGroupSide={true} groupScoreList={this.state.dataSet.groupScorelistEntries} />

            case Site.target:
                return <TargetView header={this.state.dataSet.screenConfiguration?.screenDisplaySettings?.header ?? ""} laneData={this.state.dataSet.laneDatas} targetConfiguration={this.state.dataSet.screenConfiguration?.screenDisplaySettings?.targetConfiguration} />

            default: {
                return <Registerscreen screenRegister={screenRegister} updateState={this.updateParentState}/>
            }
        }
    }


    render() {
        return <div>
            <TopLive titel={this.state.dataSet.screenConfiguration?.screenDisplaySettings?.title ?? ""}/>
            {this.getSubElement()}
        </div>


    }

    evaluateScreen(screenType: number | undefined) {
        if (screenType === undefined) {
            return Site.start;
        }
        switch (screenType) {
            case 0:
                return Site.table;
            case 1:
                return Site.target;
            case 2:
                return Site.tableWithGroup;
            default:
                return Site.start;
        }
    }

}

interface MainStageProperties {

}

interface MainStageState {
    dataSet: ScreenDataSet;
    fullScreenDate: FullScreenDataSetDto;
    screenState: Site;
}

export default withTranslation()(MainStage);