import target300A from "../images/G300A10.svg"
import target300B from "../images/G300B4.svg"
import {LaneData} from "../models/Models";
import {ReactSVG} from "react-svg";

function Target(props:TargetProps){

    var idx = (props.laneData && props.laneData.shots && props.laneData.shots.length > 10) ? props.laneData.shots.length -11 : -1;

    return <div style={{
        width: `${props.width}px`,
        height: `${props.height}px`,
        overflow: "hidden",
        padding: 10,
        borderStyle: "hidden",
        borderWidth: 1
    }}>
        <div style={{
            overflow: "hidden",
            width: `${props.width-15}px`}}>
        <ReactSVG src={target300A}
                  beforeInjection={(svg) => {
                      svg.setAttribute("width", `${props.width-5}px`);
                      svg.setAttribute("height", `${props.height - 280}px`);
                      var maxDist = 0;

                      var shots = props.laneData?.shots?.slice(-10).map((shot, idx, shots) => {
                          var dist = Math.sqrt(Math.pow(shot.xPosition??0, 2)+Math.pow(shot.yPosition??0, 2));
                          maxDist = Math.max(dist, maxDist);

                          const circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
                          circle.setAttribute('cx', shot.xPosition + "");
                          circle.setAttribute('cy', shot.yPosition + "");
                          circle.setAttribute('r', '15');
                          circle.setAttribute('fill', idx == shots.length - 1 ? 'red' : 'yellow');
                          return circle;
                      });

                      if (shots) {
                          //alert(maxDist)
                          var transform = 1;

                          if (maxDist == 0) {
                              transform = 1;
                          } else if (maxDist < 55) {
                              transform = 7;
                          }else if(maxDist < 110) {
                              transform = 5;
                          }else if(maxDist < 160) {
                              transform = 3;
                          }else if(maxDist < 200) {
                              transform = 2;
                          }


                          else if(maxDist > 300) {
                              transform = 2;
                          }else if(maxDist < 110) {
                              transform = 5;
                          }else if(maxDist < 55) {
                              transform = 7;
                          }

                          var img = svg.children.namedItem("G300A10")
                          img?.append(...shots);
                          img?.setAttribute("transform", `scale(${transform}, -${transform})`)
                      }

                  }}/>
        </div>
        <table className={"table table-borderless m-0"}>
            <tbody>
            <tr >
                <td className={"p-0"}><h1
                    style={{textAlign: "start"}}>{props.laneData?.laneNumber ?? ""} {(props.laneData && props.laneData.participantName && props.laneData.participantName.length > 0) ? ` - ${props.laneData.participantName}` : ""}</h1>
                </td>
                <td className={"p-0"}><h1 style={{textAlign: "end", fontWeight: "bolder", fontSize: "xxx-large", color: "red"}}>{(props.laneData && props.laneData.currentRank && props.laneData.currentRank > 0) ? props.laneData.currentRank : ""}</h1></td>
            </tr>
            <tr>
                <td className={"p-0"}>
                </td>
                <td className={"p-0"}><h1 style={{textAlign: "end"}}>{(props.laneData && props.laneData.currentTotal && props.laneData.currentTotal > 0) ? props.laneData.currentTotal : ""}</h1></td>
            </tr>
            </tbody>
        </table>


        <table className={"table table-bordered " + `${(props.laneData?.shots && props.laneData.shots.length > 0) ? "" : "d-none"}`}>
            <tbody>
            {
                Array.from({length: 2}, (v, i) => i).map(_ => {
                    return <tr>
                        {
                            Array.from({length: 5}, (v, i) => i).map(__ => {
                                return <td><h1>{(props.laneData && props.laneData.shots && props.laneData.shots.length > ++idx) ? props.laneData.shots[idx].value : ""}</h1></td>
                            })
                        }
                    </tr>
                })
            }
            </tbody>
        </table>
    </div>
}

interface TargetProps {
    laneData: LaneData | undefined | null;
    width: number;
    height: number;
}

export default Target;