import i18n from "i18next"
import {initReactI18next} from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";


i18n
    .use(LanguageDetector)
    .use(initReactI18next).init({
    fallbackLng: 'de',
    lng: "de",
    interpolation: {
        escapeValue: false,
    },
    resources: require('../translation.json'),
    ns: ['translations'],
    defaultNS: 'translations'

})

export default i18n;