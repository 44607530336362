import {LaneData, TargetConfiguration} from "../models/Models";
import Target from "./Target";

function TargetView(props: TargetViewProps){

    if (!props.targetConfiguration){
        return <div>
            <h1>{props.header}</h1>
        </div>
    }



    return <div>
        <h1>{props.header}</h1>
        {
            getRow()
        }
    </div>;

    function getRow(){
        var laneDataIndex = 0;
        var xCount = props.targetConfiguration?.xLaneCount??0;
        var yCount = props.targetConfiguration?.yLaneCount??0;
        var width = (window.innerWidth) / xCount;
        var height = (window.innerHeight-80) / yCount;

        return Array.from({length: yCount}, (v, i) => i).map(_ => {
            return <div className={"d-flex flex-row"}>
                {
                    Array.from({length: xCount}, (v, i) => i).map(__ => {
                        return <Target width={width} height={height}
                                laneData={(props.laneData && props.laneData.length > laneDataIndex) ? props.laneData[laneDataIndex++] : undefined}/>
                    })
                }
            </div>
        });

    }
}


interface TargetViewProps {
    header: string;
    laneData: LaneData[] | undefined | null;
    targetConfiguration: TargetConfiguration | undefined;
}

export default TargetView;