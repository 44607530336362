/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** @format int32 */
export enum DataSource {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
}

export interface GroupParticipant {
  participantName?: string | null;
  /** @format double */
  result?: number;
  /** @format double */
  bestShot?: number;
  birthyear?: string | null;
  ageCategory?: string | null;
  weaponName?: string | null;
}

export interface GroupScorelistEntry {
  /** @format int32 */
  rank?: number;
  groupName?: string | null;
  /** @format double */
  result?: number | null;
}

export interface LaneData {
  /** @format int32 */
  laneNumber?: number;
  /** @format int32 */
  currentRank?: number;
  participantName?: string | null;
  participantCity?: string | null;
  groupName?: string | null;
  /** @format double */
  currentTotal?: number;
  shots?: Shot[] | null;
  shootingProgramSequenceParts?: ShootingProgramSequencePart[] | null;
  /** @format double */
  maxPossibleResult?: number | null;
  /** @format double */
  averageShotValue?: number;
  birthyear?: string | null;
  ageCategory?: string | null;
  weaponName?: string | null;
  awardName?: string | null;
  organizationName?: string | null;
}

export interface ScoreListEntry {
  /** @format int32 */
  rank?: number;
  participantName?: string | null;
  participantCity?: string | null;
  /** @format double */
  result?: number;
  /** @format double */
  bestShot?: number;
  birthyear?: string | null;
  ageCategory?: string | null;
  weaponName?: string | null;
  awardName?: string | null;
  organizationName?: string | null;
  groupName?: string | null;
  shootingProgramName?: string | null;
  shots?: Shot[] | null;
  orderedShots?: Shot[] | null;
  groupParticipants?: GroupParticipant[] | null;
}

export interface ScreenConfiguration {
  screenIDs?: string[] | null;
  shootingRangeId?: string | null;
  screenPhysicalSettings?: ScreenPhysicalSettings;
  screenDisplaySettings?: ScreenDisplaySettings;
}

export interface ScreenDataSet {
  screenConfiguration?: ScreenConfiguration;
  scoreListEntries?: ScoreListEntry[] | null;
  groupScorelistEntries?: GroupScorelistEntry[] | null;
  laneDatas?: LaneData[] | null;
}

export interface ScreenDisplaySettings {
  screenType?: ScreenTypes;
  title?: string | null;
  subTitle?: string | null;
  header?: string | null;
  tableConfiguration?: TableConfiguration;
  targetConfiguration?: TargetConfiguration;
}

export interface ScreenPhysicalSettings {
  /** @format int32 */
  xResolution?: number;
  /** @format int32 */
  yResolution?: number;
}

export interface ScreenRegistrationDto {
  guid?: string | null;
  shootingRangeId?: string | null;
  screenId?: string | null;
  shootingRangeName?: string | null;
  screenName?: string | null;
}

/** @format int32 */
export enum ScreenTypes {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
}

export interface ShootingProgramSequencePart {
  /** @format double */
  value?: number;
  isFinished?: boolean;
  isVisible?: boolean;
  targetType?: TargetType;
}

export interface Shot {
  /** @format int32 */
  shotNr?: number;
  /** @format int32 */
  xPosition?: number;
  /** @format int32 */
  yPosition?: number;
  /** @format double */
  calibre?: number;
  /** @format double */
  value?: number;
  targetType?: TargetType;
}

export interface TableColumnConfiguration {
  headerName?: string | null;
  bold?: boolean;
  /** @format int32 */
  width?: number;
  /** @format int32 */
  fontSize?: number;
  dataField?: string | null;
  isSubTable?: boolean;
  /** @format int32 */
  subTableIndex?: number | null;
  textAlign?: string | null;
  /** @format int32 */
  marginLeft?: number;
  /** @format int32 */
  marginRight?: number;
}

export interface TableConfiguration {
  tableColumnConfigurations?: TableColumnConfiguration[] | null;
  /** @format int32 */
  maxRowCount?: number;
  dataSource?: DataSource;
  shootingProgramName?: string | null;
}

export interface TargetConfiguration {
  laneNumbers?: number[] | null;
  /** @format int32 */
  displayShotCount?: number;
  /** @format int32 */
  xLaneCount?: number;
  /** @format int32 */
  yLaneCount?: number;
}

/** @format int32 */
export enum TargetType {
  Value0 = 0,
  Value1 = 1,
  Value2 = 2,
  Value3 = 3,
}
