import {Component} from "react";

export class TopLive extends Component<TopLiveProperties,TopLiveState> {


    render() {
        return <nav className="navbar" style={{backgroundColor: "#ffeb00"}}>
            <div className="container-fluid">

                    <div className={"row col-12"}>
                        <div className={"col text-start"}><h1 className={"flextext"}>{process.env.REACT_APP_PRODUCT_NAME}</h1></div>
                        <div className={"col"}><h1 className={"flextext"}>{this.props.titel}</h1></div>
                        <div className={"col text-end flextext"}>by {process.env.REACT_APP_COMPANY_NAME}</div>
                    </div>
            </div>
        </nav>
    }
}

interface TopLiveProperties {
    titel: string;
}

interface TopLiveState {

}