import {LocalScreenSettingDto, ScreenSettingDto} from "../Dto/FullScreenDataSetDto";

export default class TypeConverter{


    static convertLocalToStdScreenSettingDto(localScreenSettingDto: LocalScreenSettingDto) : ScreenSettingDto{
        let screenSettingDto : ScreenSettingDto = {
            screenID : [localScreenSettingDto.screenID],
            eventName : localScreenSettingDto.eventName,
            rankListName : localScreenSettingDto.rankListName,
            screenName:localScreenSettingDto.screenName,
            shootingRangeName : localScreenSettingDto.shootingRangeName,
            shootingRangeId: localScreenSettingDto.shootingRangeId,
            yCountTargets : localScreenSettingDto.yCountTargets,
            xCountTargets : localScreenSettingDto.xCountTargets,
            date : localScreenSettingDto.date,
            screenType: localScreenSettingDto.screenType,
            showResulatTable: localScreenSettingDto.showResulatTable,
            eventProviderName: localScreenSettingDto.eventProviderName,
            screenResolution : {
                x_resolution : 0,
                y_resolution : 0
            }

        }
        return screenSettingDto;
    }
}