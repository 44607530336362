import * as signalR from "@microsoft/signalr";
import {LogLevel, RetryContext} from "@microsoft/signalr";
import {FullScreenDataSetDto, ScreenRegistrationDto} from "../Dto/FullScreenDataSetDto";
import {ScreenDataSet} from "../models/Models";

export class SignalRHelper {

    // @ts-ignore
    static connection

    static getConnection(){
        let url = process.env.REACT_APP_SIGNALR_ENDPOINT;
        if(url === undefined){
            url = "https://localhost:7185/api/public/live/updatehub"
        }
        if(SignalRHelper.connection === undefined){
           SignalRHelper.connection = new signalR.HubConnectionBuilder()
               .withUrl(url,{ transport: signalR.HttpTransportType.WebSockets, skipNegotiation: true })
               .build();
        }
        return SignalRHelper.connection;
    }

    static async joinScreen(screenid: string, shootingRangeId: string, updateState: Function) {

        try {
            if (this.getConnection().state === signalR.HubConnectionState.Disconnected){
                await this.getConnection().stop();
            }
        } catch (e) {
            console.log(e)
        }

        try {
            this.getConnection().on("UpdateScreen", (fulldata: ScreenDataSet) => updateState(fulldata))
            this.getConnection().onclose(() => {
                setTimeout(async () => {
                    await SignalRHelper.joinScreen(screenid, shootingRangeId, updateState);
                }, 1000);
            })
        } catch (e) {
            console.log(e)
        }

        try {
            if (this.getConnection().state === signalR.HubConnectionState.Disconnected) {
                await this.getConnection().start();
            }
            await this.getConnection().invoke("JoinScreenToEvent", {screenid, shootingRangeId});
        } catch (e) {
            console.log(e)
            setTimeout(async () => {
                await SignalRHelper.joinScreen(screenid, shootingRangeId, updateState);
            }, 1000);
        }
    }

    static async registerScreen( updateState: Function){
        try{
            await this.getConnection().stop();
        }catch(e){
            console.log(e)
        }

        try {
            this.getConnection().on("UpdateGuiDOnScreen", (screenRegisterDto: ScreenRegistrationDto) => updateState(screenRegisterDto))
            await this.getConnection().start();
            await this.getConnection().invoke("RegisterScreen");
        }catch (e){
            console.log(e)
        }
    }
}